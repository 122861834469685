.carousel-container {
  max-width: 800px; /* Adjust maximum width as needed */
  margin: 0 auto; /* Center the carousel within the dialog */
}

.carousel-slide {
  position: relative;
  text-align: center; /* Centers the image */
}

.carousel-image {
  max-width: 100%;
  height: 250px;
  max-height: 80vh; /* Adjust maximum height as needed */
  object-fit: contain; /* Maintain aspect ratio */
}

.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  color: white; /* Ensure text color is white */
  padding: 10px;
  box-sizing: border-box;
  display: none; /* Initially hidden */
  transition: display 0.3s ease;
}

.carousel-slide:hover .image-overlay {
  display: block; 
}
